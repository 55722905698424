.App {
  text-align: center;
}

.login-banner {
  background: linear-gradient(180deg, rgba(48, 137, 60, 0) 0%, #30893c 100%), url('../src/assets/images/banner.png'), no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
}
